import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
// import Vconsole from 'vconsole'
import VueTouch from 'vue-touch'

import './assets/style/global.scss'
import 'assets/style/ate.scss'
import 'assets/style/font.css'

// const vConsole = new Vconsole()

createApp(App).use(router).use(VueTouch, { name: 'v-touch' }).mount('#app')

// const app = createApp(App);
// app.use(router);
// app.mount('#app')

// router.afterEach((to,from,next) => {
//   window.scrollTo(0,0);
//  })
