<template>
  <div class="ate-body">
    <transition name="fade" mode="out-in">
      <div class="ate-header" :class="isShowHeader?'ate-header-show':'ate-header-hide'">
        <router-link to="/" class="header-tab">
          <img src="./assets/logo.png" class="header-logo">
        </router-link> 
        <router-link to="/artists"  v-show="$route.name!='Info' && $route.name!='Adetail' && $route.name!='Prodetail' && $route.name!='Pdetail'"  class="header-tab">Artists</router-link> 
        <router-link to="/production"  v-show="$route.name!='Info' && $route.name!='Adetail' && $route.name!='Prodetail' && $route.name!='Pdetail'"  class="header-tab">Production</router-link> 
        <router-link to="/programs"  v-show="$route.name!='Info' && $route.name!='Adetail' && $route.name!='Prodetail' && $route.name!='Pdetail'"  class="header-tab">Projects</router-link> 
        <router-link to="/info" v-show="$route.name!='Adetail' && $route.name!='Prodetail' && $route.name!='Pdetail'" class="header-tab">Info</router-link>
        <span v-if="$route.name!='Home'" id="pageDetailInfo"></span>
      </div>
    </transition>
    <div class="ate-contain">
      <router-view v-slot="{ Component,route  }">
          <transition name="fade" mode="out-in">
              <component :is="Component" :key="route.path"/>
          </transition>
      </router-view>
    </div>
    <span class="font-ppe"></span>
    <span class="font-ppf"></span>
    <span class="font-simsum"></span>


  </div>
</template>

<script>
export default {
  name: 'App',
  components: {
  },
  data () {
    return {
      pageDetail: '',
      isShowHeader: true,
      startY: null,
      moveEndY: null,
      moveY: null,
    }
  },
  methods: {
    handlePaste (event) {
      // 禁用鼠标右键
      event.preventDefault()
      return false
    },
  },
  mounted () {
    let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
    // console.log(flag,'手机')
    if (flag==null) {
      window.location.href = "http://www.atenothing.co/"
    }
    // 导航条显示/隐藏
    let that = this;
    document.addEventListener('touchstart', ($event) => {
      that.startY = $event.touches[0].pageY
    }, false)
    document.addEventListener('touchmove', ($event) => {
      that.moveEndY = $event.changedTouches[0].pageY;
      that.moveY = that.moveEndY - that.startY;
      // console.log('that.moveY', that.moveY)
      if ( that.moveY < -15 ) {
        if (that.isShowHeader) {
          that.isShowHeader = !that.isShowHeader
          // console.log('向上滑动, 顶部消失')
        }
      }else if ( that.moveY > 0 ) {
        if (!that.isShowHeader) {
          that.isShowHeader = !that.isShowHeader
          // console.log('向下滑动, 顶部出现')
        }
      }
    }, false)
    document.addEventListener('scroll', ($event) => {
      let sTop = document.documentElement.scrollTop
      if (sTop == 0 && !that.isShowHeader) {
        that.isShowHeader = !that.isShowHeader
        // console.log('向下滑动, 顶部出现')
      }
    }, false)
  }
}
</script>

<style lang="scss" scoped>
$r: 32;
.ate-header-show {
  animation: show 0.2s linear; 
  animation-fill-mode:forwards;
}
@keyframes show {
    0% {
      transform: translateY(-58/$r+rem);
    }
    100% {
    }
}
.ate-header-hide {
  animation: hide 0.2s linear; 
  animation-fill-mode:forwards;
}
@keyframes hide {
    0% {
    }
    100% {
      transform: translateY(-58/$r+rem);
    }
}
</style>
